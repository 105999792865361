import React from 'react';

const ResumeSkillItems = () => {
  return (
    <div className='skill-items'>
      <article>
        <h2>Fluent</h2>
        <ul>
          <li>HTML</li>
          <li>CSS + Preprocessors</li>
          <li>JavaScript - ES6 (vanilla, jQuery, React)</li>
          <li>Redux</li>
          <li>Performance optimization and debugging</li>
          <li>Cross browser/device compatibility</li>
          <li>AJAX</li>
          <li>REST API</li>
          <li>Front end testing frameworks</li>
          <li>Axios</li>
          <li>Git</li>
          <li>Webpack / Gulp / Rollup</li>
          <li>Github / Bitbucket / Gitlab</li>
          <li>npm / yarn</li>
          <li>Sketch</li>
          <li>Adobe Creative Suite</li>
        </ul>
      </article>
      <article>
        <h2>Familiar</h2>
        <ul>
          <li>Angular</li>
          <li>Node.js</li>
          <li>Express</li>
          <li>PHP / Laravel</li>
          <li>Java / Groovy / Kotlin</li>
          <li>Ruby / Rails</li> 
          <li>SQL</li>
        </ul>
      </article>
    </div>
  );
}

export default ResumeSkillItems;