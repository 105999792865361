import React from 'react';

export default function Info() {
  return (
    <section>
      <p className='paragraph'>
        I’m a <span className='callout'>front end software engineer</span> based in <span className='callout'>New York City, NY</span>. I studied studio art in college at a small, all women’s liberal arts school (print design, film photography, and printmaking), but I’ve always been interested in tech since a small age - started using computers at age 3. After graduation and a year of doing print design professionally, I craved work that would challenge me to use my creative brain in a different way than I ever had before. I attended <a href='http://www.techtalentsouth.com' className='link' target='_blank' rel='noopener noreferrer'>Tech Talent South</a>’s
        code immersion program, fell in love with coding, and never looked back. For me, front end development is the perfect fusion of creative and logical, conceiving within me a sense of passionate problem solving, dedication to my craft and product, and an unyielding curiosity.
      </p>
      <p className='paragraph'>
        I currently work at <a href='http://www.zipcar.com' className='link' target='_blank' rel='noopener noreferrer'>Zipcar</a> on the team that is engineering a technical rewrite of our member facing web application in React and Typescript (Formerly at <a className='link' title='Insight Digital Innovation' target='_blank' rel='noopener noreferrer' href='https://www.insight.com/en_US/solve/digital-innovation.html'>Cardinal Solutions [Now Insight]</a> and <a className='link' target='_blank' rel='noopener noreferrer' href='http://www.union.co'>UNION</a>). I was also previously a director for <a href='https://www.womenwhocode.com/boston' target='_blank' rel='noopener noreferrer' className='link'>Women Who Code’s Boston Chapter</a>. I’m passionate about responsive, mobile first design, making reusable components, performance, accessibility, user experience, clean & linted code, animations, and the adamant use of comma dangle.
      </p>
      <p className='paragraph'>
        In my spare time, I enjoy taking photographs of historical brownstones and row houses, traveling, trying All of The Recipes from my Pinterest boards, carefully curating Spotify playlists, and building my small but ever growing vinyl record collection. 
      </p>
      <p className='paragraph'>
        Feel free to get in touch with me - check out my resume <a href='/KaityHallman-Resume.pdf' target='_blank' rel='noopener noreferrer' className='link'>here</a>, or you can find me on <a href='http://www.twitter.com/kaityhallman' className='link' target='_blank' rel='noopener noreferrer'>Twitter</a> or <a href='https://www.linkedin.com/in/kaityhallman/' className='link' target='_blank' rel='noopener noreferrer'>LinkedIn</a>.
      </p>
    </section>
  );
}